import { useEffect, useState } from "react";
import {
	LoginScreenNavigationProp,
	routeLogin,
	ProfileNameSelectScreenNavigationProp,
	routeProfileNameSelect,
	HomeScreenNavigationProp,
	routeHome,
	routePrivacyPolicy,
} from "../../Routes";
import { AsyncStorageKeys, Pages, ProfileNameState } from "../../Types";
import { useNavigation } from "@react-navigation/native";
import { globalAny, pushProfileNameStateToBrowserHistory, generateDeviceInfo, codeLogin } from "../../utils/Utils";
import { light_black } from "../../StyleHelpers";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import TextInput from "../../components/TextInput";
import AsyncStorage from "@react-native-async-storage/async-storage";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import Toast from "../../components/Toast";
import styled from "styled-components/native";
import useGenericContentStore from "../../store/genericContent.store";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import { SafeAreaView } from "react-native";
import useLoginStore from "../../store/useLogin.store";
import useSessionStore from "../../store/useSession.store";
import { getPresignedUrl } from "../../networking/networking";
import useMqttStore from "../../store/useMqtt.store";
import CodeSignInLoader from "../../components/Loaders/CodeSignInLoader";
import { infoEmail } from "../../services/accountService";
import PrivacyPolicyButton from "../../components/PrivacyPolicyButton";

const StyledWrapper = styled.View`
	z-index: -1;
	top: 15vh;
	overflow: hidden;
	margin: 0 14.1% 70px 14.1%;
`;

const StyledButtonContainer = styled.View`
	flex-direction: column;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	margin: 0 14.1% 0 14.1%;
`;

const StyledFormContainer = styled.View`
	margin-top: 82px;
	flex-direction: row;
	width: 100%;
	gap: 10px;
`;

const CodeSignInScreen = () => {
	const navigationLoginScreen = useNavigation<LoginScreenNavigationProp>();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const navigationProfileNameSelectScreen = useNavigation<ProfileNameSelectScreenNavigationProp>();
	const [passCode, setPassCode] = useState("");
	const [passCodeErrorMessage, setPassCodeErrorMessage] = useState("");
	const [passCodeError, setPassCodeError] = useState(false);
	const [signInLoading, setSignInLoading] = useState(false);
	const [disableSignInBtn, setDisableSignInBtn] = useState(false);
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState<any>();
	const mistakeCount = useGenericContentStore((state: any) => state.mistakeCount);
	const setMistakeCount = useGenericContentStore((state: any) => state.setMistakeCount);
	const setIsLogin = useGenericContentStore((state: any) => state.setIsLogin);
	const setLoginEmail = useGenericContentStore((state: any) => state.setLoginEmail);
	const onDismissSnackBar = () => setVisible(false);
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const isSigninLoading = useSessionStore((state: any) => state.isSigninLoading);
	const setIsSigninLoading = useSessionStore((state: any) => state.setIsSigninLoading);
	const setIsLoginSuccess = useSessionStore((state: any) => state.setIsLoginSuccess);
	const isLoginSuccess = useSessionStore((state: any) => state.isLoginSuccess);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const navigation = useNavigation<any>();

	useEffect(() => {
		if (isLoggedIn && globalAny.profileName) {
			navigationHomeScreen.reset({
				index: 0,
				routes: [
					{
						name: routeHome,
					},
				],
			});
		}
	}, [isLoggedIn, globalAny.profileName]);

	useEffect(() => {
		validateMistakeCount();
	}, [signInLoading]);

	const onBackPress = () => {
		navigationLoginScreen.navigate(routeLogin, {});
	};

	const onEnterPassCode = (event: any) => {
		setPassCode(event);
		if (event.length > 0) {
			setDisableSignInBtn(false); //remove once shortcode response is standardized.
			setPassCodeError(false);
			return;
		}
	};

	const networkConnectionLost = () => {
		setLabelText(globalAny.language.label_retry);
		setIsSigninLoading(false);
		setToastMessage(globalAny.language.error_signin);
		setIsLoginSuccess(false);
		setDisableSignInBtn(false);
		setVisible(true);
	};

	const invalidSignInCode = () => {
		setLabelText(globalAny.language.label_retry);
		setToastMessage(globalAny.language.connection_unauthorized);
		setIsSigninLoading(false);
		setIsLoginSuccess(false);
		setDisableSignInBtn(false);
		setVisible(true);
	};

	const validateMistakeCount = () => {
		setLabelText(globalAny.language.label_retry);
		if (mistakeCount > 2) {
			const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
			AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
			setLabelText(null);
			setToastMessage(globalAny.language.maximum_attempt);
			setPassCode("");
		}
	};

	const onPressToast = (command: string) => {
		if (command === globalAny.language.label_retry) {
			setPassCode("");
		}
	};

	const setProfileName = async (deviceInfo: any) => {
		await AsyncStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;	
	};

	const updateDevice = (profileName: any) => {
		const deviceInfo = generateDeviceInfo(profileName);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
	};

	const validateDeviceScreen = () => {
		const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";
		const profileName = AsyncStorage.getItem(AsyncStorageKeys.profileName);

		if (!isInCarScreen) return;

		setIsLoggedIn(true);
		updateDevice(profileName);
	};

	const navigateDeviceScreen = () => {
		const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";

		if (isInCarScreen) {
			navigationHomeScreen.reset({
				index: 0,
				routes: [
					{
						name: routeHome,
					},
				],
			});
			return;
		}

		navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.main });
	};

	const loginAccount = async () => {
		setDisableSignInBtn(true);
		if (!passCode.length) {
			setPassCodeErrorMessage(globalAny.language.invalid_shortcode);
			setPassCodeError(true);
			setDisableSignInBtn(false);
			setIsLoginSuccess(false);
			return;
		}
		setIsSigninLoading(true);

		if (!passCode || passCodeError) {
			setToastMessage(globalAny.language.connection_unauthorized);
			setPassCodeError(true);
			setDisableSignInBtn(false);
			setIsLoginSuccess(false);
			return;
		} else {
			setLoginEmail("");
			let initialized = await codeLogin(
				passCode,
				setIsLoggedIn,
				() => invalidSignInCode(),
				() => networkConnectionLost()
			);
			if (initialized?.proceed) {
				const userInfo: any = await infoEmail();
				setLoginEmail(userInfo.email);
				const presignedService = await getPresignedUrl();
				setPresignedUrl(presignedService);
				setIsLogin(true);
				setIsLoginSuccess(true);
				setIsSigninLoading(false);
				pushProfileNameStateToBrowserHistory(ProfileNameState.profileNameSelect);
				AsyncStorage.setItem(AsyncStorageKeys.email, userInfo.email);
				setMistakeCount(0);
				setNavigateEvent(Pages.codeSignIn);
				validateDeviceScreen();
				setTimeout(() => {
					setDisableSignInBtn(false);
					setIsLoginSuccess(false);
					navigateDeviceScreen();
				}, 5000);
			}
		}
	};

	const handleKeyPress = ({ nativeEvent: { key: keyValue } }: any) => {
		if (keyValue === "Enter") {
			loginAccount();
		}
	};

	const onClose = () => {
		navigationLoginScreen.navigate(routeLogin, {});
	};

	const onPrivacy = () => {
		navigation.navigate(routePrivacyPolicy, {});
	};

	useEffect(() => {
		setSignInLoading(true);
		setTimeout(() => {
			setSignInLoading(false);
		}, 1000);
	}, []);

	if (signInLoading) {
		return <CodeSignInLoader />;
	}

	return (
		<SafeAreaView style={{ flex: 1, backgroundColor: light_black, height: "100%" }}>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={globalAny.language.sign_in_with_code}
				rightLogo={ridevueLogo}
				onPressLeftTitleIcon={onBackPress}
				onPressLeftIcon={onClose}
				isSingleLogo={true}
			/>

			{/* @ts-ignore */}
			<StyledWrapper>
				{/* @ts-ignore */}
				<StyledFormContainer>
					<TextInput
						componentTheme={ComponentThemeType.VinFast}
						placeholder={globalAny.language.character_code_input}
						label={globalAny.language.character_code_input}
						onChangeText={(event) => onEnterPassCode(event)}
						autoFocus={true}
						error={passCodeError}
						errorMessage={passCodeErrorMessage}
						value={passCode}
						disabled={isSigninLoading || isLoginSuccess}
						onKeyPress={handleKeyPress}
					/>
				</StyledFormContainer>
			</StyledWrapper>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				<TouchableButton
					addMarginLeft={true}
					disabled={disableSignInBtn || isLoginSuccess}
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={() => {
						loginAccount();
					}}
				>
					{globalAny.language.sign_in}
				</TouchableButton>
				<PrivacyPolicyButton onPress={onPrivacy} />
			</StyledButtonContainer>

			{visible ? (
				<Toast
					visible={visible}
					text={toastMessage}
					label={labelText}
					onDismissSnackBar={onDismissSnackBar}
					onPress={() => onPressToast(labelText)}
				/>
			) : (
				<></>
			)}
		</SafeAreaView>
	);
};

export default CodeSignInScreen;

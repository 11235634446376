import { useEffect, useState } from "react";
import { globalAny, pushCreateAccountStateToBrowserHistory, isSkipDataManagement } from "../../utils/Utils";
import { AsyncStorageKeys, CreateAccountState, Pages, SubPages, Type } from "../../Types";
import { light_black, white_smoke } from "../../StyleHelpers";
import { useNavigation } from "@react-navigation/native";
import { LoginScreenNavigationProp, routeCreateAccount, routeCreateAccountDetails, routeLogin, routePrivacyPolicy } from "../../Routes";
import { Legals } from "../../models/Legals";
import { AcceptOtherData } from "../../models/AcceptOtherData";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import CreateAccountForm from "../CreateAccountScreenComponents/CreateAccountForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import LegalTermsConditions from "../../components/LegalTermsConditions";
import OtherDataComponent from "../../components/OtherDataComponent";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import AcceptDataComponent from "../../components/AcceptDataComponent";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import styled from "rn-css";
import WrapperCreateAccount from "../../components/Layout/WrapperCreateAccount";
import PrivacyPolicyButton from "../../components/PrivacyPolicyButton";
import { AppLogger } from "../../utils/AppLogger";

const StyledOuterContainer = styled.View`
	flex: 1;
	height: 100%;
	background-color: ${light_black};
	z-index: -1;
`;

const StyledLegalTitle = styled.Text`
	font-size: 37px;
	text-align: left;
	color: ${white_smoke};

	@media (max-height: 610px) and (min-height: 500px) {
		font-size: 30px;
		margin-top: -50px;
	}
	@media (max-height: 500px) {
		font-size: 30px;
		margin-top: -80px;
	}
`;

const StyledLegalBody = styled.Text`
	font-size: 18px;
	text-align: left;
	margin-bottom: 11.56vw;
	color: ${white_smoke};

	@media (min-width: 1536px) {
		font-size: 25px;
	}
	@media (max-height: 500px) {
		font-size: 18px;
	}
	@media (max-height: 620px) and (min-height: 500px) {
		font-size: 16px;
	}
	@media (max-height: 900) and (min-height: 800px) {
		font-size: 22px;
	}
`;

const StyledLegal = styled.View`
	margin: 0 14.1% 0 14.1%;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	@media (max-height: 610px) and (min-height: 500px) {
		bottom: 15%;
	}
	@media (min-width: 1600px) and (max-width: 1600px) {
		flex-direction: row;
		bottom: 2%;
		width: 50%;
		right: 0%;
	}
`;

const CreateAccountScreen = (props: any) => {
	const navigation = useNavigation<any>();
	const { location, subLocation } = props.route.params;
	const [showWelcomeSplash, setShowWelcomeSplash] = useState(false);
	const [showActivityIndicator, setShowActivityIndicator] = useState(true);
	const [otherData, setOtherData] = useState(false);
	const [googleData, setGoogleData] = useState<any>({});
	const [videoAnalyticsData, setVideoAnalyticsData] = useState(false);
	const [state, setState] = useState(globalAny.language.create_account);
	const [acceptOtherData, setAcceptOtherData] = useState<AcceptOtherData>();
	const [legalText, setLegalText] = useState<Legals>();
	const [privacyPolicyScreen, setPrivacyPolicyScreen] = useState(false);
	const [googleAnalytics, setGoogleAnalytics] = useState("");
	const [videoAnalytics, setVideoAnalytics] = useState("");
	const [sonyPictures, setSonyPictures] = useState("");
	const [sonyCompany, setSonyCompany] = useState("");
	const [acceptDataTitle, setAcceptDataTitle] = useState("");
	const [acceptDataDescription, setAcceptDataDescription] = useState("");
	const [otherDataTitle, setOtherDataTitle] = useState("");
	const [otherDataDescription, setOtherDataDescription] = useState("");
	const [legalDescription, setLegalDescription] = useState("");
	const [legalData, setLegalData] = useState<Legals>();
	const navigationLoginScreen = useNavigation<LoginScreenNavigationProp>();
	const [createAccountState, setCreateAccountState] = useState("");
	const skipDataManagement = isSkipDataManagement();

	const onNextButtonPress = () => {
		if (skipDataManagement) {
			return navigation.push(routeCreateAccount, { location: Pages.createAccount });
		}
		switch (createAccountState) {
			case CreateAccountState.legalOne:
				navigation.push(routeCreateAccount, { location: Pages.acceptData });
				break;
			case CreateAccountState.legalTwo:
				navigation.push(routeCreateAccount, { location: Pages.otherData });
				break;
			case CreateAccountState.legalThree:
				navigation.push(routeCreateAccount, { location: Pages.legalTerms });
				break;
			case CreateAccountState.legalFour:
				navigation.push(routeCreateAccount, { location: Pages.createAccount });
				break;
		}
	};

	const onPrivacy = () => {
		navigation.push(routePrivacyPolicy);
	};

	const onBackPress = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}

		navigation.navigate(routeLogin);
	};

	const loadAcceptData = (name: any, key: string) => {
		try {
			let currentAcceptData = acceptOtherData?.sections ? acceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === key) : null;
			let sonyCompanyText = null;
			if (!currentAcceptData) {
				const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataCreate) ?? "{}");
				currentAcceptData = cacheAcceptOtherData?.sections ? cacheAcceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === key) : [];
			}

			sonyCompanyText = currentAcceptData[0]?.textValue ? `
			<span style="display: flex align-items: center">
				<label target="_blank" style="display: flex align-items: center margin-right: 500px margin-top:-100px">
				${currentAcceptData[0]?.textValue}
				</label>
			</span>
		` : '';

			name === "Sony Pictures"
				? setSonyPictures(currentAcceptData[0]?.textValue)
				: setSonyCompany(sonyCompanyText);
			setState(name);
		} catch {
			AppLogger.log("Error Missing Legals");
		}
	};

	const onPressSonyPictures = () => {
		loadAcceptData("Sony Pictures", "section-read-more-1");
	};

	const onPressSonyCompany = () => {
		loadAcceptData("Sony Group Companies", "section-read-more-2");
	};

	const loadOtherData = (name: any) => {
		try {
			let currentOtherData = acceptOtherData?.sections ? acceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === name) : null;
			if (!currentOtherData) {
				const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataCreate) ?? "{}");
				currentOtherData = cacheAcceptOtherData?.sections ? cacheAcceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === name) : null;
			}
			const data = currentOtherData ? currentOtherData[0]?.optionsWordings?.filter((val: any) => val?.key === "option-read-more-1") : [];
			const title = currentOtherData ? currentOtherData[0]?.optionsWordings?.filter((val: any) => val?.key === "option-title") : [];
			name === "Google Analytics" ? setGoogleAnalytics(data[0]?.textValue) : setVideoAnalytics(data[0]?.textValue);
			setState(title[0]?.textValue);
		} catch {
			AppLogger.log("Error Missing Legals");
		}
	};

	const onPressGoogle = () => {
		loadOtherData("Google Analytics");
	};

	const onPressVideoAnalytics = () => {
		loadOtherData("Video Analytics");
	};

	const onLegal = async (keyValue: string, type: number) => {
		let data = legalData?.legalContents?.filter((val: any) => val?.type === type);
		if (!data) {
			const cacheLegalData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheLegalDataCreate) ?? "{}");
			data = cacheLegalData?.legalContents?.filter((val: any) => val?.type === type);
		}
		if (data) {
			switch (keyValue) {
				case globalAny.language.promotional_terms:
					setState(globalAny.language.promotional_terms);
					setLegalText(data[0]?.htmlContent);
					break;
				case globalAny.language.terms_of_use:
					setState(globalAny.language.terms_of_use);
					setLegalText(data[0]?.htmlContent);
					break;

				case globalAny.language.terms_of_service:
					setState(globalAny.language.terms_of_service);
					setLegalText(data[0]?.htmlContent);
					break;
			}
		}
	};

	const onClose = () => {
		setCreateAccountState(CreateAccountState.legalOne);
		pushCreateAccountStateToBrowserHistory(CreateAccountState.legalOne);
		navigationLoginScreen.navigate(routeLogin, {});
	};

	const fetchLegalData = () => {
		let titleDescription;
		if (!acceptOtherData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataCreate) ?? "{}");

			if (!cacheAcceptOtherData) return;

			titleDescription = cacheAcceptOtherData?.sections ? cacheAcceptOtherData?.sections[0]?.sectionWording?.find((val: any) => val?.key === "section-description") : null;
			setLegalDescription(titleDescription?.textValue ?? globalAny.language.legal_one_body);
			return;
		}

		titleDescription = acceptOtherData?.sections ? acceptOtherData?.sections[0]?.sectionWording?.find((val: any) => val?.key === "section-description") : null;
		setLegalDescription(titleDescription?.textValue ?? globalAny.language.legal_one_body);
	};

	const fetchAcceptData = () => {
		let titleData;
		let titleDescription;
		if (!acceptOtherData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataCreate) ?? "{}");

			if (!cacheAcceptOtherData) return;

			titleData = cacheAcceptOtherData?.sections ? cacheAcceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-title") : null;
			titleDescription = cacheAcceptOtherData?.sections ? cacheAcceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-description") : null;
			setAcceptDataTitle(titleData?.textValue);
			setAcceptDataDescription(titleDescription?.textValue);
			return;
		}

		titleData = acceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-title");
		titleDescription = acceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-description");
		setAcceptDataTitle(titleData?.textValue);
		setAcceptDataDescription(titleDescription?.textValue);
	};

	const fetchOtherData = () => {
		let titleData;
		let titleDescription;
		if (!acceptOtherData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataCreate) ?? "{}");

			if (!cacheAcceptOtherData) return;

			titleData = cacheAcceptOtherData?.sections ? cacheAcceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-title") : null;
			titleDescription = cacheAcceptOtherData?.sections ? cacheAcceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find(
				(val: any) => val?.key === "section-description"
			) : null;
			setOtherDataTitle(titleData?.textValue);
			setOtherDataDescription(titleDescription?.textValue);
			return;
		}

		titleData = acceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-title");
		titleDescription = acceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-description");
		setOtherDataTitle(titleData?.textValue);
		setOtherDataDescription(titleDescription?.textValue);
	};

	useEffect(() => {
		(async () => {
			const resultLegal = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheLegalDataCreate) ?? '{}');
			const resultAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataCreate) ?? '{}');
			setLegalData(resultLegal);
			setAcceptOtherData(resultAcceptOtherData)
			fetchLegalData();
			loadAcceptData("Sony Pictures", "section-read-more-1");
			loadAcceptData("Sony Group Companies", "section-read-more-2");
			loadOtherData("Google Analytics");
			loadOtherData("Video Analytics");
			setState(globalAny.language.create_account);
			setShowActivityIndicator(false);
		})();
	}, []);

	useEffect(() => {
		if (!googleData && !videoAnalyticsData) {
			setOtherData(false);
		}

		if (googleData && videoAnalyticsData) {
			setOtherData(true);
		}
	}, [googleData, videoAnalyticsData]);

	useEffect(() => {
		setGoogleData(otherData);
		setVideoAnalyticsData(otherData);
	}, [otherData]);

	useEffect(() => {
		switch (subLocation) {
			case SubPages.termsOfUse:
				onLegal(globalAny.language.terms_of_use, 6);
				return;
			case SubPages.termsOfService:
				onLegal(globalAny.language.terms_of_service, 3);
				return;
			case SubPages.sonyPictures:
				onPressSonyPictures();
				return;
			case SubPages.sonyGroup:
				onPressSonyCompany();
				return;
			case SubPages.googleAnalytics:
				onPressGoogle();
				return;
			case SubPages.videoAnalytics:
				onPressVideoAnalytics();
				return;
		}

		switch (location) {
			case Pages.legal:
				setCreateAccountState(CreateAccountState.legalOne);
				break;
			case Pages.acceptData:
				fetchAcceptData();
				setCreateAccountState(CreateAccountState.legalTwo);
				break;
			case Pages.otherData:
				fetchOtherData();
				setCreateAccountState(CreateAccountState.legalThree);
				break;
			case Pages.legalTerms:
				setCreateAccountState(CreateAccountState.legalFour);
				break;
			case Pages.createAccount:
				setCreateAccountState(CreateAccountState.newCreateAccountForm);
				break;
			case Pages.privacy:
				setPrivacyPolicyScreen(true);
				setState(globalAny.language.privacy_policy);
				break;
		}
	}, [location, subLocation]);

	if (showWelcomeSplash) {
		return <LoadingIndicator show={true} text={globalAny.language.account_created} showSpinner={true} />;
	}

	if (showActivityIndicator) {
		return <LoadingIndicator show={showActivityIndicator}></LoadingIndicator>;
	}

	return (
		//@ts-ignore
		<StyledOuterContainer>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={state}
				rightLogo={ridevueLogo}
				onPressLeftIcon={onClose}
				onPressLeftTitleIcon={onBackPress}
				isSingleLogo={true}
			/>

			{createAccountState === CreateAccountState.newCreateAccountForm && !subLocation && (
				<CreateAccountForm
					accountInfo={{
						ageChecked: false,
						acceptTC: true,
						acceptPrivacy: true,
						email: "",
						password: "",
						confirmPassword: "",
						month: 0,
						year: 0,
						deviceIdentifier: `WEB-MERCEDES`,
						deviceModel: "WEB",
						softwareVersion: "6",
					}}
					setShowWelcomeSplash={setShowWelcomeSplash}
					onPressPrivacy={onPrivacy}
				/>
			)}
			{createAccountState == CreateAccountState.legalOne &&
				!subLocation &&
				(skipDataManagement ? (
					<LegalTermsConditions
						bodyText={globalAny.language.i_read_agree}
						showButton={true}
						onPressNext={() => onNextButtonPress()}
						// onPressPromotionalTerms={() => onLegal(globalAny.language.promotional_terms, 7)}
						onPressTermsOfUse={() =>
							navigation.push(routeCreateAccountDetails, { location: Pages.legalTerms, subLocation: SubPages.termsOfUse })
						}
						onPressTermsOfService={() =>
							navigation.push(routeCreateAccountDetails, { location: Pages.legalTerms, subLocation: SubPages.termsOfService })
						}
						onPressPrivacy={onPrivacy}
					/>
				) : (
					//@ts-ignore
					<WrapperCreateAccount>
						{/* @ts-ignore */}
						<StyledLegalTitle>{globalAny.language.legal_data}</StyledLegalTitle>
						{/* @ts-ignore */}
						<StyledLegalBody>{legalDescription ?? globalAny.language.legal_one_body}</StyledLegalBody>
					</WrapperCreateAccount>
				))}
			{createAccountState == CreateAccountState.legalTwo && !subLocation && (
				//@ts-ignore
				<AcceptDataComponent
					onPressSecondaryButton={() => onBackPress()}
					onPressPrimaryButton={() => onNextButtonPress()}
					onPressSonyCompany={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.acceptData, subLocation: SubPages.sonyGroup })
					}
					onPressSonyPictures={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.acceptData, subLocation: SubPages.sonyPictures })
					}
					onPressPrivacy={() => onPrivacy()}
					showPrivacyLink={true}
					type={Type.CreateAccount}
					title={acceptDataTitle}
					description={acceptDataDescription}
					sonyPictures={sonyPictures}
					sonyCompany={sonyCompany}
				/>
			)}
			{subLocation === SubPages.sonyPictures && (
				//@ts-ignore
				<PrivacyPolicy text={sonyPictures} />
			)}

			{subLocation === SubPages.sonyGroup && (
				//@ts-ignore
				<PrivacyPolicy text={sonyCompany} />
			)}

			{/* Other data */}
			{createAccountState == CreateAccountState.legalThree && !subLocation && (
				<OtherDataComponent
					onSubmit={(val) => setOtherData(val)}
					onChangeGoogleData={(val) => setGoogleData(val)}
					onChangeVideoAnalyticsData={(val) => setVideoAnalyticsData(val)}
					onPressSecondaryButton={onBackPress}
					onPressPrimaryButton={onNextButtonPress}
					onPressGoogle={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.otherData, subLocation: SubPages.googleAnalytics })
					}
					onPressVideoAnalytics={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.otherData, subLocation: SubPages.videoAnalytics })
					}
					onPressPrivacy={onPrivacy}
					showPrivacyLink={true}
					title={otherDataTitle}
					description={otherDataDescription}
					googleAnalytics={googleAnalytics}
					videoAnalytics={videoAnalytics}
				/>
			)}

			{subLocation === SubPages.googleAnalytics && (
				//@ts-ignore
				<PrivacyPolicy text={googleAnalytics} />
			)}

			{subLocation === SubPages.videoAnalytics && (
				//@ts-ignore
				<PrivacyPolicy text={videoAnalytics} />
			)}

			{createAccountState == CreateAccountState.legalFour && !subLocation && (
				<LegalTermsConditions
					bodyText={globalAny.language.i_read_agree}
					showButton={true}
					onPressBack={() => onBackPress()}
					onPressNext={() => onNextButtonPress()}
					// onPressPromotionalTerms={() => onLegal(globalAny.language.promotional_terms, 7)}
					onPressTermsOfUse={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.legalTerms, subLocation: SubPages.termsOfUse })
					}
					onPressTermsOfService={() =>
						navigation.push(routeCreateAccountDetails, { location: Pages.legalTerms, subLocation: SubPages.termsOfService })
					}
					onPressPrivacy={onPrivacy}
				/>
			)}

			{(subLocation === SubPages.termsOfUse || subLocation === SubPages.termsOfService) && (
				//@ts-ignore
				<PrivacyPolicy text={legalText} />
			)}

			{(createAccountState == CreateAccountState.legalOne ||
				createAccountState == CreateAccountState.validateVoucher ||
				createAccountState == CreateAccountState.termsAndConditions) &&
				!privacyPolicyScreen && (
					// @ts-ignore

					<StyledLegal>
						<TouchableButton componentTheme={ComponentThemeType.VinFast} onPress={onNextButtonPress}>
							{skipDataManagement ? globalAny.language.i_agree : globalAny.language.next}
						</TouchableButton>
						<PrivacyPolicyButton onPress={onPrivacy} />
					</StyledLegal>
				)}
		</StyledOuterContainer>
	);
};

export default CreateAccountScreen;

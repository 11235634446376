import { Text, StyleSheet, View } from "react-native";
import { black, spacing_l, spacing_m, white } from "../../StyleHelpers";
import styled, { css } from "styled-components/native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import PlayIcon from "../../assets/Icons/Play.png";
import AssignIcon from "../../assets/Icons/ScreenManager.png";
import Image from "../../components/Image/Image";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import GenresComponent from "../../components/GenresComponent";
import { useEffect, useState } from "react";
import ImgProgressBar from "../../components/Loaders/ImgProgressBar";
import { globalAny } from "../../utils/Utils";
import useGenericContentStore from "../../store/genericContent.store";
import { AsyncStorageKeys, CarActivity } from "../../Types";
import useDrivingStore from "../../store/useDriving.store";
import useToggleStore from "../../store/useToggle.store";

const StyledContainer = styled.View`
	width: 58%;
	height: 100vh;
	padding: 0 0 0 122px;
	z-index: -1;
`;

const StyledButtonLoader = styled.View`
	height: 72px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: ${spacing_m};
`;

const StyledButtonContainer = styled.View<any>`
	width: 81%;
	${(props) =>
		!props.isButtonVisible
			? css`
					display: block;
			  `
			: css`
					display: none;
			  `}
`;

const StyledButton = styled.View`
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

const StyledButtonText = styled.View`
	left: -10;
`;

interface Buttons {
	text: string;
	icon: string;
	theme: ComponentThemeType;
	type: ComponentTypeEnum;
	onPress: () => void;
}

const ViewDetails = (props: any) => {
	const isToggleMQTT = useToggleStore((state: any) => state.isToggleMQTT);
	const buttonDataDefault = props.isPreview ?
	[
		props.isTrailer && {
			text: globalAny.language.trailer,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Secondary,
			onPress: props.onTrailer,
			redeem: false,
		}
	] :
	[
		{
			text: props.streamProgress ? globalAny.language.resume : globalAny.language.play,
			icon: PlayIcon,
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPlay,
			redeem: true,
		},
		{
			text: globalAny.language.redeem_for_one,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Tertiary,
			onPress: props.onRedeemMovie,
			redeem: false,
			isShow: true,
		},
		{
			text: globalAny.language.purchase,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Primary,
			onPress: props.onPurchase,
			redeem: false,
			isShow: true,
		},
		isToggleMQTT && {
			text: globalAny.language.assign_to_screens,
			icon: AssignIcon,
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Secondary,
			onPress: props.onAssignScreen,
			redeem: true,
		},
		props.isTrailer && {
			text: globalAny.language.trailer,
			icon: "",
			theme: ComponentThemeType.VinFast,
			type: ComponentTypeEnum.Secondary,
			onPress: props.onTrailer,
			redeem: false,
		},
	];

	const [isButtonVisible, setIsButtonVisible] = useState(false);
	const [data, setData] = useState({});
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const isRenderTDP = useGenericContentStore((state: any) => state.isRenderTDP);
	const isRenderRedeem = useGenericContentStore((state: any) => state.isRenderRedeem);
	const isSuccess = useGenericContentStore((state: any) => state.isSuccess);
	const accountDetails = JSON.parse(localStorage.getItem(AsyncStorageKeys.accountDetails) ?? "[]");
	const [userDetails] = accountDetails.length ? JSON.parse(accountDetails?.data).filter((item: any) => item?.creditsAvailable) : [];

	const goToDetails = () => {
		setSelectedTabs(1);
	};

	useEffect(() => {
		setIsButtonVisible(false);
	}, [isRenderTDP]);


	useEffect(() => {
		setIsButtonVisible(false);
		if (props.isLoadingButton && !props.isPreview) {
			setIsButtonVisible(false);
			return;
		}
		setIsButtonVisible(true);
	}, [props.isLoadingButton]);

	useEffect(() => {
		let buttonData = buttonDataDefault
			.filter((val) => val.redeem === props.redeemed || val.text === globalAny.language.trailer || val.isShow)
			.map((item) => {
				if (item.text === globalAny.language.redeem_for_one && !props.availabilityRedeem) return;
				if (
					(item.text === globalAny.language.redeem_for_one && props.isRedeem) ||
					(item.text === globalAny.language.redeem_for_one && props.redeemed && !props?.isRented?.owned)
				)
					return;
				if (
					(item.text === globalAny.language.purchase && props.isPurchase) ||
					(item.text === globalAny.language.purchase && props.redeemed && !props?.isRented?.owned)
				)
					return;
				if (availabilityChecking(item)) return;
				return { ...item };
			});
		setData(buttonData);
	}, [props.redeemed, props?.streamProgress, props?.isTrailer, isRenderRedeem]);

	const availabilityChecking = (item: any) => {
		if (
			item.text === globalAny.language.purchase &&
			isSuccess.result &&
			isSuccess.isRented &&
			userDetails?.creditsAvailable > 0 &&
			!props.isRedeem
		)
			return true;
		if (item.text === globalAny.language.purchase && props?.isRented?.owned && userDetails?.creditsAvailable > 0) return true;
	};

	const renderButtons = (item: Buttons, id: number) => {
		return (
			<TouchableButton
				key={`tdp-button-${id}`}
				addMarginRight={true}
				fontColor={item.type === ComponentTypeEnum.Tertiary ? black : white}
				componentTheme={item.theme}
				type={item.type}
				isStreamProgress={props.streamProgress && item.text === globalAny.language.resume ? props.streamProgress : 0}
				onPress={item.onPress}
				style={{ marginBottom: spacing_m }}
				disabled={item.text !== globalAny.language.assign_to_screens && carActivity === CarActivity.INMOTION}
			>
				{/* @ts-ignore */}
				<StyledButton>
					{item.icon ? (
						<>
							<Image
								source={{ uri: item.icon }}
								width={56}
								height={56}
								style={{ left: item.text !== globalAny.language.assign_to_screens ? -19 : -11 }}
							/>
							{/* @ts-ignore */}
							<StyledButtonText style={{ left: -10 }}>
								<Text>{item.text}</Text>
							</StyledButtonText>
						</>
					) : (
						<Text>{item.text}</Text>
					)}
				</StyledButton>
			</TouchableButton>
		);
	};

	return (
		//@ts-ignore
		<StyledContainer>
			{props.metadata && (
				<View>
					<GenresComponent
						rating={props?.rating}
						genres={props?.genres}
						expiryDate={props?.expiryDate}
						isRent={props.streamProgress !== null && props.streamProgress === undefined ? false : props?.isRent}
					/>
					<Text style={[styles.metadata, { fontSize: 18, width: "80%", marginBottom: spacing_l }]}>{props?.ratingReason}</Text>

					<TouchableOpacity onPress={goToDetails}>
						<Text
							style={[styles.metadata, { fontSize: 25, width: "80%", marginBottom: spacing_l }]}
							numberOfLines={3}
							ellipsizeMode="tail"
						>
							{props?.synopsis}
						</Text>
					</TouchableOpacity>
				</View>
			)}

			{/* @ts-ignore */}
			<StyledButtonContainer isButtonVisible={isButtonVisible}>
				{/* @ts-ignore */}
				<StyledButtonLoader>
					<ImgProgressBar />
				</StyledButtonLoader>

				{/* @ts-ignore */}
				<StyledButtonLoader>
					<ImgProgressBar />
				</StyledButtonLoader>

				{/* @ts-ignore */}
				<StyledButtonLoader>
					<ImgProgressBar />
				</StyledButtonLoader>
			</StyledButtonContainer>

			{isButtonVisible && (
				//  @ts-ignore
				<StyledButtonContainer>
					{/* @ts-ignore */}
					{data.map((item: any, index: number) => (item ? renderButtons({ ...item }, index) : <></>))}
				</StyledButtonContainer>
			)}
		</StyledContainer>
	);
};

export default ViewDetails;

const styles = StyleSheet.create({
	metadata: {
		color: "white",
	},
	button: {
		fontSize: 25,
	},
});
